import Box from "@mui/material/Box";
import { styled, keyframes } from "@mui/material/styles";
import { benefactors } from "../util/MockData";
import { Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const scrolling = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
`;

const Carousel = styled(Box)({
  margin: "0 auto",
  padding: "20px 0",
  //   maxWidth: "min(100vw, 1200px)",
  maxWidth: "100vw",
  overflow: "hidden",
  display: "flex",
  "& > *": {
    flex: "0 0 100%",
  },
  //   "&:hover .group": {
  //     animationPlayState: "paused",
  //   },
});

const Group = styled(Box)({
  display: "flex",
  willChange: "transform",
  animation: `${scrolling} ${benefactors.length * 2}s linear infinite`,
  // animationDelay: "1s",
});

const Card = styled(Box)({
  width: "100%",
  paddingRight: 50,
  fontSize: 25,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  whiteSpace: "nowrap",
});

const Image = styled("img")(({ theme }) => ({
  height: "70px",
  [theme.breakpoints.up("sm")]: {
    height: "80px",
  },
  [theme.breakpoints.up("md")]: {
    height: "90px",
  },
  [theme.breakpoints.up("lg")]: {
    height: "100px",
  },
}));

const Benefactors = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        minHeight: "100%",
        mb: 5,
      }}
    >
      <Carousel>
        {[...Array(2)].map((_, groupIndex) => (
          <Group
            key={groupIndex}
            aria-hidden={groupIndex === 1}
            className="group"
          >
            {benefactors.map((item, i) => (
              <Card key={i}>
                {item.imageUrl ? (
                  <Image src={item.imageUrl} alt="Benefactor" />
                ) : (
                  <Typography
                    variant="h4"
                    color={theme.palette.primary.main}
                    sx={{ fontFamily: "cursive" }}
                  >
                    {item.text}
                  </Typography>
                )}
              </Card>
            ))}
          </Group>
        ))}
      </Carousel>
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
          overflow: "hidden",
          height: "100%",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: "50%",
            transform: "translateX(-50%)",
            width: "100%",
            height: "100%",
            backgroundImage:
              "url(https://firebasestorage.googleapis.com/v0/b/cyrenaeus-870fa.appspot.com/o/images%2Fb%26s_heart.jpg?alt=media&token=ff3df47d-a108-4e18-b837-aa5f733d01b2)",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "cover",
            filter: "blur(1px)",
            zIndex: -1,
          }}
        />
        <Box
          sx={{
            height: "100%",
            width: "100%",
            padding: 4,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: `
      radial-gradient(circle, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 80%)`,
          }}
        >
          <Box
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              textAlign: "center",
              maxWidth: 800,
              padding: "20px",
            }}
          >
            <Typography
              variant="h3"
              sx={{
                fontFamily: "cursive",
                color: "white",
              }}
            >
              Thank You!
            </Typography>
            <Typography
              variant="h4"
              sx={{
                color: theme.palette.primary.main,
                textShadow: `
        -1px -1px 0 white,  
         1px -1px 0 white,  
        -1px  1px 0 white,  
         1px  1px 0 white`,
                paddingBottom: 5,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                "&::before, &::after": {
                  content: '""',
                  flex: 1,
                  height: "2px",
                  backgroundColor: "white",
                  margin: "0 10px",
                },
              }}
            >
              ♥
            </Typography>
            <Typography
              sx={{
                pr: 1,
                fontSize: 20,
                textAlign: "justify",
                color: "white",
                paddingBottom: 3,
                "&::first-letter": {
                  color: theme.palette.primary.main,
                  fontFamily: '"IBM Plex Mono"',
                  fontSize: "4rem",
                  fontWeight: "bold",
                  lineHeight: "0.9",
                  float: "left",
                  marginRight: "8px",
                  WebkitInitialLetter: 2,
                  initialLetter: 2,
                  textShadow: `
        -1px -1px 0 white,  
         1px -1px 0 white,  
        -1px  1px 0 white,  
         1px  1px 0 white`,
                },
              }}
            >
              Here at Cyrenaeus, we are all extremely grateful for the
              generosity of our donors and sponsors, who have made this project
              possible. As a charity, we operate solely on a voluntary basis,
              and as such, every single donation is directly used for the
              benefit of our patients.{" "}
              <strong>You are making a difference, thank you!</strong>
            </Typography>
            <Typography sx={{ fontSize: 20, color: "white" }}>
              Sincerely,
            </Typography>
            <Typography
              variant="h4"
              sx={{
                fontFamily: "cursive",
                color: "white",
              }}
            >
              Team Cyrenaeus
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Benefactors;
