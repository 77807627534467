import { useParams } from "react-router-dom";
import Markdown from "react-markdown";
import ImageRenderer from "../util/ImageRenderer";
import { reports } from "../util/MockData";
import Box from "@mui/material/Box";
import NotFound from "./NotFound";

const Mission = () => {
  const { id } = useParams();

  const mission = reports[id];

  if (mission == null) return <NotFound/>;

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box sx={{ maxWidth: 1000, padding: 2 }}>
        <Markdown
          components={{
            img: ImageRenderer,
          }}
          children={mission.content}
        />
      </Box>
    </Box>
  );
};

export default Mission;
