import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

const MissionCard = (props) => {
  const handleCardClick = (url) => {
    if (url && url !== "") window.location.href = url;
  };

  return (
    <Card sx={{maxWidth: 500, width: "100%"}}>
      <CardActionArea
        onClick={() => {
          handleCardClick(props.destination);
        }}
      >
        <CardMedia sx={{height: 300}} image={props.imgUrl} />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {props.title}
          </Typography>
          <Typography variant="subtitle1" sx={{color: "text.secondary"}}>
            {props.location}
          </Typography>
          <Typography variant="body2" sx={{color: "text.secondary"}}>
            {props.description}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Box
          sx={{display: "flex", justifyContent: "space-between", width: "100%"}}
        >
          <Button
            onClick={() => {
              handleCardClick(props.destination);
            }}
            size="small"
          >
            More Info
          </Button>
          <Box sx={{display: "flex", gap: 2}}>
            <Typography
              variant="body2"
              sx={{
                alignSelf: "center",
                color: "white",
                bgcolor: props.status === "Upcoming" ? "red" : "green",
                padding: 1,
                borderRadius: 2,
              }}
            >
              {props.status}
            </Typography>
            <Typography
              variant="body2"
              sx={{alignSelf: "center", color: "text.secondary"}}
            >
              {props.date}
            </Typography>
          </Box>
        </Box>
      </CardActions>
    </Card>
  );
};

export default MissionCard;
