import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

const NewsCard = (props) => {
  const handleCardClick = (url) => {
    window.open(url, "_blank");
  };

  return (
    <Card sx={{ maxWidth: 500 }}>
      <CardActionArea
        onClick={() => {
          handleCardClick(props.articleUrl);
        }}
      >
        <CardMedia sx={{ height: 300 }} image={props.imageUrl} />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {props.title}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button
          onClick={() => {
            handleCardClick(props.articleUrl);
          }}
          size="small"
        >
          Read Article
        </Button>
      </CardActions>
    </Card>
  );
};

export default NewsCard;
