import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {
  FaInstagram,
  FaFacebook,
  FaLinkedin,
  FaEnvelope,
} from "react-icons/fa";

const Socials = () => {
  const theme = useTheme();

  const style = {
    color: theme.palette.common.white,
    fontSize: 30,
  };

  return (
    <Box
      sx={{ bgcolor: theme.palette.primary.main, textAlign: "center", py: 2 }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: 2,
          mb: 1, // Adds spacing between icons and text
        }}
      >
        <IconButton
          onClick={() =>
            window.open("https://www.instagram.com/cyrenaeus", "_blank")
          }
        >
          <FaInstagram style={style} />
        </IconButton>
        <IconButton
          onClick={() =>
            window.open(
              "https://www.facebook.com/profile.php?id=100093034806609",
              "_blank"
            )
          }
        >
          <FaFacebook style={style} />
        </IconButton>
        <IconButton
          onClick={() =>
            window.open("https://www.linkedin.com/company/cyrenaeus/", "_blank")
          }
        >
          <FaLinkedin style={style} />
        </IconButton>
        <IconButton
          onClick={() => window.open("mailto:admin@cyrenaeus.com", "_blank")}
        >
          <FaEnvelope style={style} />
        </IconButton>
      </Box>
      <Typography
        variant="caption"
        sx={{ color: "lightGrey", textDecoration: "none" }}
        component="a"
        href="https://register-of-charities.charitycommission.gov.uk/en/charity-search/-/charity-details/5211178/charity-overview"
        target="_blank"
        rel="noopener noreferrer"
      >
        UK registered charity. Charity number: 1202741
      </Typography>
    </Box>
  );
};

export default Socials;
