export default function NotFound() {
    return (
      <div style={{ padding: "2rem", textAlign: "center" }}>
        <img
          src="/favicon.ico"
          alt="Site Icon"
          style={{ width: "128px", height: "128px", borderRadius: "50%" }}
        />
        <h1>404 - Page Not Found</h1>
        <p>The page you're looking for doesn't exist.</p>
      </div>
    );
  }