const ImageRenderer = ({ src, alt }) => {
  const [altText, ...options] = alt.split("|").map((item) => item.trim());

  const widthMatch = options.find((opt) => opt.startsWith("width="));
  const responsive = options.includes("responsive=true");
  const isCentered = options.includes("center=true");

  const widthStyle = responsive
    ? { maxWidth: "100%", width: "100%", maxHeight: "auto" }
    : { width: widthMatch ? widthMatch.split("=")[1] + "px" : "300px", height: "auto" };

  const image = (
    <img
      src={src}
      alt={altText}
      style={{
        ...widthStyle,
        display: "block",
      }}
    />
  );

  return isCentered ? (
    <div style={{ display: "flex", justifyContent: "center" }}>{image}</div>
  ) : (
    image
  );
};


export default ImageRenderer;
